import axios from 'axios'
import { env } from '../../../config/environment'

export const fetchCategories = async () => {
	try {
		const response = await axios.get(`${env.apiUrl}/categories`)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}
