import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../protected-route'

const PartnerHomePage = lazy(() => import('../../../../pages/partner/home'))
const PartnerAccountPage = lazy(() =>
	import('../../../../pages/partner/account')
)
const PartnerCompaniesPage = lazy(() =>
	import('../../../../pages/partner/companies')
)
const PartnerCompaniesFormPage = lazy(() =>
	import('../../../../pages/partner/companies-form')
)
const PartnerPaymentsPage = lazy(() =>
	import('../../../../pages/partner/payments')
)
const PartnerOrdersPage = lazy(() => import('../../../../pages/partner/orders'))
const PartnerZonesPage = lazy(() => import('../../../../pages/partner/zones'))
const PartnerReportsPage = lazy(() =>
	import('../../../../pages/partner/reports')
)
const WhatsappConfigPage = lazy(() =>
	import('../../../../pages/partner/whatsapp')
)
const DeliveryCompany = lazy(() =>
	import('../../../../pages/partner/delivered-company')
)
const PartnerExpensesAndBasesPage = lazy(() =>
	import('../../../../pages/partner/expenses-bases')
)
const RequestsNewCollaboratorsPage = lazy(() =>
	import('../../../../pages/partner/requests')
)
const SchedulesPage = lazy(() => import('../../../../pages/business/schedules'))
const PromoPage = lazy(() => import('../../../../pages/business/promo'))
const ProductPage = lazy(() => import('../../../../pages/business/product'))
const ProductsPage = lazy(() => import('../../../../pages/business/products'))
const BusinessPage = lazy(() => import('../../../../pages/business/business'))
const UploadProductsPage = lazy(() =>
	import('../../../../pages/business/upload-products')
)
const UploadAdditionsPage = lazy(() =>
	import('../../../../pages/business/upload-additions')
)
const DeliveryRidersPage = lazy(() =>
	import('../../../../pages/delivery/riders')
)

const partnerRoutes = (business, type, selectCompany, selectCompanyType) => (
	<>
		<Route
			path='/zones'
			element={
				<ProtectedRoute business={business}>
					<PartnerZonesPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/payments'
			element={
				<ProtectedRoute business={business}>
					<PartnerPaymentsPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/account'
			element={
				<ProtectedRoute business={business}>
					<PartnerAccountPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/companies/create'
			element={
				<ProtectedRoute business={business}>
					<PartnerCompaniesFormPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/companies'
			element={
				<ProtectedRoute business={business}>
					<PartnerCompaniesPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/orders'
			element={
				<ProtectedRoute business={business}>
					<PartnerOrdersPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/reports'
			element={
				<ProtectedRoute business={business}>
					<PartnerReportsPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/whatsapp'
			element={
				<ProtectedRoute business={business}>
					<WhatsappConfigPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/home'
			element={
				<ProtectedRoute business={business}>
					<PartnerHomePage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/delivered'
			element={
				<ProtectedRoute business={business}>
					<DeliveryCompany business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/expenses'
			exact
			element={
				<ProtectedRoute business={business}>
					<PartnerExpensesAndBasesPage
						typeEntity='expense'
						business={business}
						type={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/bases'
			exact
			element={
				<ProtectedRoute business={business}>
					<PartnerExpensesAndBasesPage
						typeEntity='base'
						business={business}
						type={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/schedules'
			element={
				<ProtectedRoute business={business}>
					<SchedulesPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/product/:id'
			element={
				<ProtectedRoute business={business}>
					<ProductPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/promo/:id'
			element={
				<ProtectedRoute business={business}>
					<PromoPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products/list'
			element={
				<ProtectedRoute business={business}>
					<UploadProductsPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products/additions/list'
			element={
				<ProtectedRoute business={business}>
					<UploadAdditionsPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products'
			element={
				<ProtectedRoute business={business}>
					<ProductsPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/information'
			element={
				<ProtectedRoute business={business}>
					<BusinessPage
						business={selectCompany}
						type={selectCompanyType}
						partner={business}
						partnerType={type}
					/>
				</ProtectedRoute>
			}
		/>
		<Route
			path='/riders'
			element={
				<ProtectedRoute business={business}>
					<DeliveryRidersPage business={business} type={type} />
				</ProtectedRoute>
			}
		/>
		{business && business.role === 'admin' && (
			<Route
				path='/requests'
				element={
					<ProtectedRoute business={business}>
						<RequestsNewCollaboratorsPage business={business} type={type} />
					</ProtectedRoute>
				}
			/>
		)}
	</>
)

export default partnerRoutes
