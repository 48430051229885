import axios from 'axios'
import { Buffer } from 'buffer'
import { env } from '../../../config/environment'

export const fetchOrdersPag = async (companyId, limit, offset) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/orders/company/pag?company=${companyId}&limit=${limit}&offset=${offset}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchOrderReport = async (companyId, from, to) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/orders/reports?ID=${companyId}&from=${from}&to=${to}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchOrderStatsByMonth = async (companyId, month, year) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/orders/stats/month?company=${companyId}&month=${month}&year=${year}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const setOrderStatus = async (orderId, status, manager) => {
	try {
		const encodedManager = Buffer.from(manager).toString('base64')
		const response = await axios.put(
			`${env.apiUrl}/orders/status?ID=${orderId}&status=${status}`,
			{},
			{ headers: { 'Order-Manager': encodedManager } }
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const setOrderTime = async (orderId, minutes) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/orders/minutes?ID=${orderId}&minutes=${minutes}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const setOrderRider = async (orderId, riderInfo) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/orders/rider/set?ID=${orderId}`,
			riderInfo
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const rejectOrder = async (payload) => {
	try {
		const response = await axios.post(`${env.apiUrl}/rejections`, payload)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchOrderById = async (id) => {
	try {
		const response = await axios.get(`${env.apiUrl}/orders/order?ID=${id}`)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const countOrdersByCityState = async (payload) => {
	try {
		const response = await axios.post(
			`${env.apiUrl}/orders/city-state/month`,
			payload
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchOrdersByCityState = async (payload, limit, offset) => {
	try {
		const response = await axios.post(
			`${env.apiUrl}/orders/city-state?limit=${limit}&offset=${offset}`,
			payload
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchErrandsByPartner = async (partner, limit, offset) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/errands/partner?limit=${limit}&offset=${offset}&partner=${partner}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const setErrandStatus = async (errand, status, manager) => {
	try {
		const encodedManager = Buffer.from(manager).toString('base64')
		const response = await axios.put(
			`${env.apiUrl}/errands/status?ID=${errand}&status=${status}`,
			{},
			{ headers: { 'Order-Manager': encodedManager } }
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const setErrandTime = async (errand, minutes) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/errands/minutes?ID=${errand}&minutes=${minutes}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchClipboardData = async (order) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/orders/clipboard?ID=${order}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}
