const AUTH_KEY = 'agus-business@auth'
const LASTZONE_KEY = 'agus-url-or-lastzone'
const SELECT_COMPANY_KEY = 'select-company-agus-partner'

/**
 * @param {string} json
 * @returns {any|null}
 */

const safeParse = (json) => {
	try {
		return JSON.parse(json)
	} catch {
		return null
	}
}

export const getLocalAuth = () => {
	try {
		const auth = localStorage.getItem(AUTH_KEY)
		return auth ? safeParse(auth) : null
	} catch {
		console.error('Something went wrong.')
		return null
	}
}

export const setLocalAuth = (auth) => {
	try {
		localStorage.setItem(AUTH_KEY, JSON.stringify(auth))
	} catch {
		console.error('Something went wrong.')
	}
}

export const updatedOpenData = (open) => {
	try {
		const auth = localStorage.getItem(AUTH_KEY)
		if (auth) {
			const authObject = safeParse(auth)
			if (authObject && authObject.data) {
				authObject.data.open = open
				localStorage.setItem(AUTH_KEY, JSON.stringify(authObject))
			}
		} else {
			console.error('Something went wrong.')
		}
	} catch {
		console.error('Something went wrong.')
	}
}

export const updatedAuth = (data) => {
	try {
		const auth = localStorage.getItem(AUTH_KEY)
		if (auth) {
			const authObject = safeParse(auth)
			if (authObject && authObject.data) {
				authObject.data = { ...authObject.data, ...data }
				localStorage.setItem(AUTH_KEY, JSON.stringify(authObject))
			}
		} else {
			console.error('Something went wrong.')
		}
	} catch {
		console.error('Something went wrong.')
	}
}

export const clearLocalAuth = () => {
	try {
		localStorage.removeItem(AUTH_KEY)
	} catch {
		console.error('Something went wrong.')
	}
}

export const setlastZone = (route) => {
	try {
		localStorage.setItem(LASTZONE_KEY, JSON.stringify(route))
	} catch {
		console.error('Something went wrong.')
	}
}

export const getlastZone = () => {
	try {
		const storedRoute = localStorage.getItem(LASTZONE_KEY)
		return storedRoute ? safeParse(storedRoute) : null
	} catch {
		console.error('Something went wrong.')
		return null
	}
}

export const clearlastZone = () => {
	try {
		localStorage.removeItem(LASTZONE_KEY)
	} catch {
		console.error('Something went wrong.')
	}
}

export const setSelectCompanyLocal = (company) => {
	try {
		localStorage.setItem(SELECT_COMPANY_KEY, JSON.stringify(company))
	} catch {
		console.error('Something went wrong.')
	}
}

export const getSelectCompanyLocal = () => {
	try {
		const company = localStorage.getItem(SELECT_COMPANY_KEY)
		return company ? safeParse(company) : null
	} catch {
		console.error('Something went wrong.')
		return null
	}
}

export const clearCompanyLocal = () => {
	try {
		localStorage.removeItem(SELECT_COMPANY_KEY)
	} catch {
		console.error('Something went wrong.')
	}
}
