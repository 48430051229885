import axios from 'axios'
import { env } from '../../../config/environment'

export const fetchPartnerByEmail = async (email) => {
	try {
		const response = await axios.post(`${env.apiUrl}/partners/email`, { email })
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const addDevice = async (partnerID, device) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/partners/devices/add?ID=${partnerID}&device=${device}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const removeDevice = async (partnerID, device) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/partners/devices/remove?ID=${partnerID}&device=${device}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchPaymentsToDo = async (partnerID) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/partners/payments?ID=${partnerID}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchPendingPayments = async (partnerID) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/partners/payments/pending?ID=${partnerID}`
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchPartners = async () => {
	try {
		const response = await axios.get(`${env.apiUrl}/partners`)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const updatePartner = async (partnerID, partner) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/partners?ID=${partnerID}`,
			partner
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const updateSecrets = async (partnerID, config) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/partners/config/secrets?ID=${partnerID}`,
			config
		)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}
