import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { addDevice as addCompanyDevice } from '../../../../services/api/company/company'
import { addDevice as addPartnerDevice } from '../../../../services/api/partner/partners'
import { initMessaging } from '../../../../services/firebase/messaging'
import { onMessage } from 'firebase/messaging'
import { messaging } from '../../../../services/firebase/config'
import { fetchOrderById } from '../../../../services/api/orders/orders'
import {
	addErrand,
	addOrder,
	selectOrder,
	updateErrand,
	updateOrder,
} from '../../../../redux/features/orderSlice'
import { socket } from '../../../../config/sockets'
import { setWaQr, setWaStatus } from '../../../../redux/features/whatsappSlice'
import {
	fetchProductsByIds,
	fetchPromosByIds,
} from '../../../../services/api/products/products'
import { addProducts, addPromos } from '../../../../redux/features/productSlice'

const audio = new Audio('/assets/sounds/agus-alert.mp3')

const Notifications = ({ business, type, dispatch }) => {
	const { orders, selectedOrder, errands } = useSelector(({ orders }) => orders)
	const toast = useToast()

	const playAudio = () => {
		audio.play()
	}

	const _initMessaging = async () => {
		try {
			let addDevice = type === 'partner' ? addPartnerDevice : addCompanyDevice
			const token = await initMessaging()
			await addDevice(business.id, token)

			navigator.serviceWorker.addEventListener('message', (event) => {
				if (event.data && event.data.command === 'playSound') {
					const audio = new Audio('/assets/sounds/agus-alert.mp3')
					audio.play()
				}
			})

			if (type === 'company') {
				onMessage(messaging, async (payload) => {
					if (payload.data.type === 'new:order') {
						const orderExists = orders.find(
							(order) => order.id === payload.data.id
						)
						if (!orderExists) {
							playAudio()
							try {
								const { data: order } = await fetchOrderById(payload.data.id)
								dispatch(addOrder(order))
							} catch (error) {}
							toast({
								title: 'Nuevo pedido!',
								description: 'Tienes un nuevo pedido en la plataforma.',
								status: 'success',
								duration: 9000,
								isClosable: true,
							})
						}
					}
				})
			}
		} catch (error) {
			if (error.name === 'AbortError') {
				console.warn(
					'Error en _initMessaging: AbortError - El registro falló, posible problema con el servicio de push. Intenta recargar la página o verificar los permisos de notificación.'
				)
			} else {
				console.error('Error en _initMessaging:', error)
			}
		}
	}

	const connectedToEvents = () => {
		console.log('Listening to events')
		setTimeout(() => {
			if (business.role === 'admin') {
				socket.emit('whatsapp:fetch')
			}
		}, 3000)
	}

	const disconnectedToEvents = () => {
		console.log('Disconnected to events')
	}

	const fetchNewProducts = async (order) => {
		const productsIds = order.products.map((p) => p.product)
		const promoProducts = order.promos?.flatMap((p) =>
			p.options.map((o) => o.product)
		)
		const promosIds = order.promos?.map((p) => p.promo)

		const [productsRes, promosRes] = await Promise.allSettled([
			fetchProductsByIds([...(productsIds ?? []), ...(promoProducts ?? [])]),
			fetchPromosByIds(promosIds),
		])

		if (productsRes?.value.length > 0) {
			dispatch(addProducts(productsRes.value))
		}
		if (promosRes?.value.length > 0) {
			dispatch(addPromos(promosRes.value))
		}
	}

	useEffect(() => {
		return () => {
			socket.off('connect', connectedToEvents)
			socket.off('disconnect', disconnectedToEvents)
			socket.disconnect()
		}
	}, [])

	useEffect(() => {
		if (business && type) {
			_initMessaging()
			socket.connect()
			socket.on('connect', connectedToEvents)
			socket.on('disconnect', disconnectedToEvents)
			socket.on(`new:order:${business.id}`, async (payload) => {
				if (orders.find((order) => order.id === payload.id)) {
					return
				}
				await fetchNewProducts(payload)
				console.log('najsndfjkasndfkjasndf')
				dispatch(addOrder(payload))
				playAudio()
			})
			socket.on(`update:order:${business.id}`, (payload) => {
				dispatch(updateOrder(payload))
				if (selectedOrder && selectedOrder.id === payload.id) {
					dispatch(selectOrder(payload))
				}
			})

			if (business.type === 'partner') {
				socket.on(`new:errand:${business.id}`, (payload) => {
					if (!errands.find((errand) => errand.id === payload.id)) {
						playAudio()
						dispatch(addErrand(payload))
					}
				})
				socket.on(`update:errand:${business.id}`, (payload) => {
					dispatch(updateErrand(payload))
					if (selectedOrder && selectedOrder.id === payload.id) {
						dispatch(selectOrder(payload))
					}
				})
			}

			if (business.role === 'admin') {
				socket.on('whatsapp:connection', (payload) => {
					dispatch(setWaStatus(payload))
					if (payload.connection === 'close') {
						socket.emit('whatsapp:fetch')
					}
				})
				socket.on('whatsapp:qr', (payload) => {
					dispatch(setWaQr(payload))
				})
				socket.emit('whatsapp:fetch')
			}
		}
	}, [business])

	return null
}

export default Notifications
