import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../protected-route'

const DeliveryAccountPage = lazy(() =>
	import('../../../../pages/delivery/account')
)
const DeliveryOrdersPage = lazy(() =>
	import('../../../../pages/delivery/orders')
)
const DeliveryRidersPage = lazy(() =>
	import('../../../../pages/delivery/riders')
)

const deliveryRoutes = (business, type) => (
	<>
		<Route
			path='/account'
			element={
				<ProtectedRoute business={business.data}>
					<DeliveryAccountPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/'
			element={
				<ProtectedRoute business={business.data}>
					<DeliveryOrdersPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/riders'
			element={
				<ProtectedRoute business={business.data}>
					<DeliveryRidersPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
	</>
)

export default deliveryRoutes
