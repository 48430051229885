import React from 'react'
import { Center, VStack, Spinner, Text } from '@chakra-ui/react'
import { appColors } from '../../../../utils/colors'

const Loading = () => {
	return (
		<Center
			width="100%"
			height="100%"
			bg="white"
			zIndex={0}
		>
			<VStack spacing={4}>
				<Spinner size="xl" color={appColors.primary} />
				<Text fontSize="lg" color="gray.700">
					Cargando...
				</Text>
			</VStack>
		</Center>
	)
}

export default Loading
