import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	authUser: null,
	business: null,
	type: '',
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, action) => {
			state.business = action.payload
			state.type = action.payload?.type
		},
		setCompany: (state, action) => {
			state.business = action.payload
		},
		updateCompanyOpen: (state, action) => {
			if (state.business && state.business.data) {
				state.business.data.open = action.payload
			}
		},
		updateCompanyData: (state, action) => {
			if (state.business) {
				state.business = {
					...state.business,
					data: {
						...state.business.data,
						...action.payload,
					},
				}
			}
		},
		clearAuthData: (state) => {
			state.authUser = null
			state.business = null
			state.type = ''
		},
	},
})

export const {
	login,
	setCompany,
	updateCompanyOpen,
	updateCompanyData,
	clearAuthData,
} = authSlice.actions

export default authSlice.reducer
