import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	product: null,
	promo: null,
	products: [],
	additions: [],
	promos: [],
	productsMap: {},
	additionsMap: {},
	promosMap: {},
}

export const productSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		selectProduct: (state, action) => {
			state.product = action.payload
		},
		selectPromo: (state, action) => {
			state.promo = action.payload
		},
		setProducts: (state, action) => {
			state.products = Array.isArray(action.payload) ? action.payload : []
			state.productsMap = state.products.reduce((acc, product) => {
				acc[product.id] = product
				return acc
			}, {})
		},
		addProducts: (state, action) => {
			state.products = [...new Set([...state.products, ...action.payload])]
			state.productsMap = state.products.reduce((acc, product) => {
				acc[product.id] = product
				return acc
			}, {})
		},

		setAdditions: (state, action) => {
			state.additions = Array.isArray(action.payload) ? action.payload : []
			state.additionsMap = state.additions.reduce((acc, addition) => {
				acc[addition.id] = addition
				return acc
			}, {})
		},

		setPromos: (state, action) => {
			state.promos = Array.isArray(action.payload) ? action.payload : []
			state.promosMap = state.promos.reduce((acc, promo) => {
				acc[promo.id] = promo
				return acc
			}, {})
		},
		addPromos: (state, action) => {
			state.promos = [...state.promos, ...action.payload]
			state.promosMap = state.promos.reduce((acc, promo) => {
				acc[promo.id] = promo
				return acc
			}, {})
		},
	},
})

export const {
	selectProduct,
	selectPromo,
	setProducts,
	setAdditions,
	setPromos,
	addProducts,
	addPromos,
} = productSlice.actions

export default productSlice.reducer
