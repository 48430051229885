import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChakraProvider } from '@chakra-ui/react'

import AppRoutes from './components/layout/routes/routes'

import { setIsMobileScreen } from './redux/features/mediaQuerySlice'
import { store } from './redux/store'

const queryClient = new QueryClient()

function App() {
	useEffect(() => {
		const isDesktop = window.matchMedia('(min-width: 768px)').matches
		store.dispatch(setIsMobileScreen(!isDesktop))
		window
			.matchMedia('(min-width: 768px)')
			.addEventListener('change', e =>
				store.dispatch(setIsMobileScreen(!e.matches))
			)
	}, [])

	return (
		<ChakraProvider>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<AppRoutes />
				</QueryClientProvider>
			</Provider>
		</ChakraProvider>
	)
}

export default App
