import { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from 'react-router-dom'
import TheRescuerURL from '../session/rescuer'
import companyRoutes from './path/company-routes'
import partnerRoutes from './path/partner-routes'
import deliveryRoutes from './path/delivery-routes'
import Loading from '../main/parts/loading'
import ProtectedRoute from './protected-route'
import Notifications from './sockets/notifications'
import { fetchCategories } from '../../../services/api/categories/categories'
import { setCategories } from '../../../redux/features/categoriesSlice'
import { fetchPartners } from '../../../services/api/partner/partners'
import { setPartners } from '../../../redux/features/partnersSlice'
import { setlastZone } from '../../../services/app/localStorage'

const AboutPage = lazy(() => import('../../../pages/about'))
const NotFoundPage = lazy(() => import('../../../pages/not-found'))
const LoginPage = lazy(() => import('../../../pages/login'))
const SupportPage = lazy(() => import('../../../pages/support'))

const getRoutePattern = (pathname) => {
	return pathname
		.split('/')
		.map((segment) => (/^\d+$/.test(segment) ? ':id' : segment))
		.join('/')
}

const RouteChangeTracker = () => {
	const location = useLocation()
	useEffect(() => {
		const currentPattern = getRoutePattern(location.pathname)
		if (currentPattern === '/login' || currentPattern === '/not-found') {
			return
		}
		setlastZone(currentPattern)
	}, [location])
	return null
}

const AppRoutes = () => {
	const { business, type } = useSelector(({ auth }) => auth)
	const [loadingAuth, setLoadingAuth] = useState(true)
	const dispatch = useDispatch()
	const { selectCompany, selectCompanyType } = useSelector(
		({ companies }) => companies
	)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const categories = await fetchCategories()
				dispatch(setCategories(categories))
			} catch {
				console.error('Something went wrong.')
			}

			try {
				const partners = await fetchPartners()
				dispatch(setPartners(partners))
			} catch {
				console.error('Something went wrong.')
			}
		}

		setTimeout(() => {
			setLoadingAuth(false)
		}, 300)

		fetchData()
	}, [dispatch])

	if (loadingAuth) {
		// return console.log('Cargando..')
	}

	return (
		<Router>
			{business && type && <RouteChangeTracker />}
			<Notifications business={business} type={type} dispatch={dispatch} />
			<Suspense fallback={<Loading />}>
				<Routes>
					{type === 'company' && companyRoutes(business, type)}
					{type === 'partner' &&
						partnerRoutes(business, type, selectCompany, selectCompanyType)}
					{type === 'deliveries-provider' && deliveryRoutes(business, type)}
					<Route
						path='/support'
						element={
							<ProtectedRoute business={business}>
								<SupportPage
									business={type == 'company' ? business.data : business}
									type={type}
								/>
							</ProtectedRoute>
						}
					/>
					<Route path='/' element={<Navigate to='home' />} />
					<Route path='/about' element={<AboutPage />} />
					<Route path='/not-found' element={<NotFoundPage />} />
					<Route path='/login' element={<LoginPage />} />
					<Route
						path='*'
						element={
							business ? (
								<Navigate to='/not-found' />
							) : (
								<TheRescuerURL to='/login' />
							)
						}
					/>
				</Routes>
			</Suspense>
		</Router>
	)
}

export default AppRoutes
