import axios from 'axios'
import { env } from '../../../config/environment'

export const fetchCompany = async (email) => {
	try {
		const response = await axios.post(`${env.apiUrl}/companies/email`, {
			email,
		})
		return response.data
	} catch (error) {
		console.error('Error fetching company:', error)
		throw error
	}
}

export const updateCompany = async (id, body) => {
	try {
		const response = await axios.put(`${env.apiUrl}/companies?ID=${id}`, body)
		return response.data
	} catch (error) {
		console.error('Error updating company:', error)
		throw error
	}
}

export const updateBanner = async (urlBanner, companyId) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/banner?ID=${companyId}`,
			JSON.stringify(urlBanner)
		)
		return response.data
	} catch (error) {
		console.error('Error updating banner:', error)
		throw error
	}
}

export const updateImage = async (urlImage, companyId) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/image?ID=${companyId}`,
			JSON.stringify(urlImage)
		)
		return response.data
	} catch (error) {
		console.error('Error updating image:', error)
		throw error
	}
}

export const fetchStats = async (companyId) => {
	try {
		const response = await axios.get(
			`${env.apiUrl}/companies/stats?company=${companyId}`
		)
		return response.data
	} catch (error) {
		console.error('Error fetching stats:', error)
		throw error
	}
}

export const addDevice = async (companyId, device) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/devices/add?company=${companyId}&device=${device}`
		)
		return response.data
	} catch (error) {
		console.error('Error adding device:', error)
		throw error
	}
}

export const removeDevice = async (companyId, device) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/devices/remove?company=${companyId}&device=${device}`
		)
		return response.data
	} catch (error) {
		console.error('Error removing device:', error)
		throw error
	}
}

export const createCompany = async (body) => {
	try {
		const response = await axios.post(`${env.apiUrl}/companies`, body)
		return response.data
	} catch (error) {
		console.error('Error creating company:', error)
		throw error
	}
}

export const fetchCompaniesByCityState = async (city, state) => {
	try {
		const response = await axios.post(
			`${env.apiUrl}/companies/city-state/all`,
			{ city, state }
		)
		return response.data
	} catch (error) {
		console.error('Error fetching companies by city/state:', error)
		throw error
	}
}

export const countCompaniesByCityState = async (city, state) => {
	try {
		const response = await axios.post(
			`${env.apiUrl}/companies/city-state/count`,
			{ city, state }
		)
		return response.data
	} catch (error) {
		console.error('Error counting companies by city/state:', error)
		throw error
	}
}

export const fetchCompaniesIdIn = async (ids) => {
	try {
		const response = await axios.post(`${env.apiUrl}/companies/id-in`, { ids })
		return response.data
	} catch (error) {
		console.error('Error fetching companies by ID list:', error)
		throw error
	}
}

export const updateStatus = async (companyId, status) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/status?ID=${companyId}&status=${status}`
		)
		return response.data
	} catch (error) {
		console.error('Error updating status:', error)
		throw error
	}
}

export const openCompany = async (companyId, open) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/open?ID=${companyId}&open=${open ? 1 : 0}`
		)
		return response.data
	} catch (error) {
		console.error('Error opening company:', error)
		throw error
	}
}

export const updateSchedules = async (companyId, schedules) => {
	try {
		const response = await axios.put(
			`${env.apiUrl}/companies/schedules?ID=${companyId}`,
			schedules
		)
		return response.data
	} catch (error) {
		console.error('Error updating schedules:', error)
		throw error
	}
}
