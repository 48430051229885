import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../protected-route'

const HomePage = lazy(() => import('../../../../pages/business/home'))
const ProductsPage = lazy(() => import('../../../../pages/business/products'))
const BusinessPage = lazy(() => import('../../../../pages/business/business'))
const ProductPage = lazy(() => import('../../../../pages/business/product'))
const ReportPage = lazy(() => import('../../../../pages/business/reports'))
const OrdersPage = lazy(() => import('../../../../pages/business/orders'))
const AdsPage = lazy(() => import('../../../../pages/ads'))
const PaymentsPage = lazy(() => import('../../../../pages/business/payments'))
const SchedulesPage = lazy(() => import('../../../../pages/business/schedules'))
const PromoPage = lazy(() => import('../../../../pages/business/promo'))
const UploadProductsPage = lazy(() =>
	import('../../../../pages/business/upload-products')
)
const UploadAdditionsPage = lazy(() =>
	import('../../../../pages/business/upload-additions')
)

const companyRoutes = (business, type) => (
	<>
		<Route
			path='/payments'
			element={
				<ProtectedRoute business={business}>
					<PaymentsPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/ads'
			element={
				<ProtectedRoute business={business}>
					<AdsPage type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/orders'
			element={
				<ProtectedRoute business={business}>
					<OrdersPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/schedules'
			element={
				<ProtectedRoute business={business}>
					<SchedulesPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/product/:id'
			element={
				<ProtectedRoute business={business}>
					<ProductPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/promo/:id'
			element={
				<ProtectedRoute business={business}>
					<PromoPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/reports'
			element={
				<ProtectedRoute business={business}>
					<ReportPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/information'
			element={
				<ProtectedRoute business={business}>
					<BusinessPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products/list'
			element={
				<ProtectedRoute business={business}>
					<UploadProductsPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products/additions/list'
			element={
				<ProtectedRoute business={business}>
					<UploadAdditionsPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/products'
			element={
				<ProtectedRoute business={business}>
					<ProductsPage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
		<Route
			path='/home'
			element={
				<ProtectedRoute business={business}>
					<HomePage business={business.data} type={type} />
				</ProtectedRoute>
			}
		/>
	</>
)

export default companyRoutes
