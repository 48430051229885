import axios from 'axios'
import { env } from '../../../config/environment'

export const fetchProducts = async (id) => {
	try {
		const url = `${env.apiUrl}/products/company?company=${id}`
		const response = await axios.get(url)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const updateProducts = async (body) => {
	try {
		const url = `${env.apiUrl}/products?ID=${body.id}`
		const response = await axios.put(url, body)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const createProducts = async (body) => {
	try {
		const url = `${env.apiUrl}/products`
		const response = await axios.post(url, body)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const createProductsList = async (body) => {
	try {
		const url = `${env.apiUrl}/products/list`
		const response = await axios.post(url, body)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchProductsByIds = async (ids) => {
	try {
		const url = `${env.apiUrl}/products/ids`
		const response = await axios.post(url, { ids })
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchPromos = async (id) => {
	try {
		const url = `${env.apiUrl}/promos/company?company=${id}`
		const response = await axios.get(url)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const fetchPromosByIds = async (ids) => {
	try {
		const url = `${env.apiUrl}/promos/ids`
		const response = await axios.post(url, { ids })
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const createPromo = async (body) => {
	try {
		const url = `${env.apiUrl}/promos`
		const response = await axios.post(url, body)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}

export const updatePromo = async (body) => {
	try {
		const url = `${env.apiUrl}/promos?ID=${body.id}`
		const response = await axios.put(url, body)
		return response.data
	} catch (error) {
		throw new Error('somewhere do had a error')
	}
}
